import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/about.png";
import Tilt from "react-parallax-tilt";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              I am passionate about developing software that makes everyday life easier 😀
              <br />
              <br />I am fluent in
              <i>
                <b className="purple"> Python, JavaScript, SQL, React, </b>
              </i>
              and a bit of
              <i>
                <b className="purple"> C & Kotlin</b>
              </i>
              <br />
              <br />
              My fields of interest are building
              <i>
                <b className="purple">Web Technologies and Data Science Solutions, </b>
              </i>
              <br />
              <br />
              I am also an <b className="purple">Analytical Chemist (BSc)</b> with experience in pharmaceutical & food analysis
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar"  />
            </Tilt>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
